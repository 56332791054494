import PropTypes from 'prop-types'
import { Box } from '@tofu/shared/ui/atoms/box'
import { Text } from '@tofu/shared/ui/atoms/text'
import { breakpoints } from '@tofu/apps/shop/styles/variables'
import { TrustpilotWidget } from '@tofu/shop/ui/molecules/trustpilot-widget'

const TrustPilot = ({ background_color, title, subtitle, variant }) => {
  return (
    variant && (
      <Box data-testid='background' bg={background_color}>
        <Box maxWidth={`${breakpoints.desktop}px`} mx='auto' py={8}>
          {title[0]?.text && (
            <Text as='h2' textStyle='h3' pb={3} px={[4, 6]} textAlign='center'>
              {title[0].text}
            </Text>
          )}
          {subtitle[0]?.text && (
            <Text pb={3} px={[4, 6]} textAlign='center'>
              {subtitle[0].text}
            </Text>
          )}
          <TrustpilotWidget variant={variant} />
        </Box>
      </Box>
    )
  )
}

TrustPilot.defaultProps = {
  background_color: '#ffffff',
  title: [],
  subtitle: [],
  variant: 'top9'
}

TrustPilot.propTypes = {
  background_color: PropTypes.string,
  title: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.oneOf(['micro', 'slim', 'standard', 'top9'])
}

export default TrustPilot
